import React, {useRef, useEffect} from "react";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ArrowDown from "../../../../Assets/Images/arrow-down.svg";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ColorCheckbox = withStyles({
  root: {
    color: (props) => props.color,
    padding: "0px",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function ServiceLegend(props) {
  // eslint-disable-next-line no-unused-vars
  const [allKeys, setAllKeys] = React.useState([
    //"Bringing Justice",
    "CSEY Advocacy",
    "Drop-In Center",
    "Emergency Shelter",
    //"Innovative Services",
    "Long Term Residential",
    //"Prevention",
    //"Therapy/Counseling",
    //"Other",
    "National Referral Directory",
  ]);

  const [keys, setKeys] = React.useState([
    //"Bringing Justice",
    "CSEY Advocacy",
    "Drop-In Center",
    "Emergency Shelter",
    //"Innovative Services",
    "Long Term Residential",
    //"Prevention",
    //"Therapy/Counseling",
    //"Other",
    "National Referral Directory",
  ]);

  const colors = [
    //"#F28E2B",
    "#4E79A7",
    "#E15759",
    "#76B7B2",
    //"#59A14E",
    "#EDC949",
    //"#B07AA2",
    //"#FF9DA7",
    //"#9C755F",
    "#BAB0AC", //National Referral Directory
  ];
  const buttonRef = useRef(null)
  const popperRef = useRef(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [popperRefHeight, setPopperRefHeight] = React.useState(100)

  const handleLegendClick = (e, key) => {
    if (keys.includes(key)) {
      setKeys(keys.filter((_key) => _key !== key));
      props.handleLegendClick(keys.filter((_key) => _key !== key));
    } else {
      var newArray = [...keys];
      newArray.push(key);
      setKeys(newArray);
      props.handleLegendClick(newArray);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  useEffect(() => {
    if (props.report) {
      setAnchorEl(buttonRef.current)
      if (popperRef.current && popperRef.current.clientHeight) {

        setPopperRefHeight(popperRef.current.clientHeight)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.report, popperRef.current])

  return (
    <div className={!props.report ? 'mapLegendWrapper' : 'reportMapLegendWrapper'}
      style={props.report && { height: `calc(${popperRefHeight}px + 30px)` }}
    >
      <Button
        ref={buttonRef}
        aria-describedby={id}
        variant="contained"
        color="default"
        type="button"
        onClick={handleClick}
        className="filterButton legendButton"
      >
        Legend {
            props.shouldUseNewStyles
                ? <ArrowDropDownIcon />
                : <img src={ArrowDown} alt="Arrow Down" />
        }
      </Button>
      <Popper
        ref={popperRef}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"top-start"}
        transition
        disablePortal // (the children will stay within their parent DOM hierarchy for pdf's to capture it)
        modifiers={props.report && {
          flip: {
            enabled: false,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <div className="maplegend">
                <p style={{ margin: 0 }}>Services</p>
                {allKeys.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className="field"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {keys.includes(item) ? (
                        <ColorCheckbox
                          checked={true}
                          color={colors[key]}
                          onClick={(e) => handleLegendClick(e, item)}
                        />
                      ) : (
                        <ColorCheckbox
                          color={colors[key]}
                          checked={false}
                          style={{ padding: "0px" }}
                          onClick={(e) => handleLegendClick(e, item)}
                        />
                      )}
                      <label
                        htmlFor={key}
                        style={{ fontSize: "12px", marginLeft: "5px" }}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
