import React, { useEffect, Fragment } from "react";
import { tdcjdata } from "../../GraphComponents/GraphVariables/TDCJ";
import { tdcjmockdata } from "./TDCJData";
import SingleCard from "../../../../Components/Card";
import { StackedBarGraph } from "../../GraphComponents/StackedBarGraph";
import { GroupedBarVariableXGraph } from "../../GraphComponents/GroupedBarVariableXAxis";
import TDCJTable from './Tables/TDCJTable'
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";


export default function TDCJ(props) {
    //   const [graphData, setGraphData] = React.useState([]);
    //   // eslint-disable-next-line no-unused-vars
    //   const [graphStackedData, setGraphStackedData] = React.useState(
    //     []
    //   );

    //   useEffect(() => {
    //       setGraphData(props.data)
    //       setGraphStackedData(props.data);
    //   }, [props.data])

    useEffect(() => {
        console.log('props.data', props.data)
    }, [props.data])

    // const addToReports = (data) => {
    //   props.addToReportPage(
    //     data,
    //   );
    // };

    const renderAllCharts = () => {
        return (
            <div>
                <div>

                    <SingleCard
                        width="100%"
                        id="Offense Information Inmates by Year"
                        title="Offense Information  >  Inmates by Year"
                        graph={
                            <GroupedBarVariableXGraph
                                data={props.data.inmateByYear}
                                keys={tdcjdata.inmateByYear}
                                xaxis="Year"
                                yaxis="Number of Inmates"
                                multipleYMaxValues={['offenseYear', 'sentenceYear']}
                            />
                        }
                        table={<TDCJTable data={props.data.inmateByYear} keys={tdcjdata.inmateByYear} id="Inmates by Year" />}
                        definitions={[["", "This chart shows the number of inmates per year, based on the year that they committed the offense and the year that they were sentenced."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        width="100%"
                        id="Offense Information Length of Sentence"
                        title="Offense Information  >  Length of Sentence"
                        graph={
                            <SingleBarGraph
                                data={props.data.lengthSentence ? props.data.lengthSentence : tdcjmockdata.lengthSentence}
                                keys={tdcjdata.lengthSentence}
                                xaxis="Length of Sentence (Years)"
                                yaxis="Number of Inmates"
                                type="cc"
                            />
                        }
                        table={<TDCJTable data={props.data.lengthSentence} keys={tdcjdata.lengthSentence} id="Length of Sentence" single={true} />}
                        definitions={[["", "This chart shows the length of each inmate’s sentence in years."]]}
                        addToReports={props.addToReportPage}
                    />

                        <SingleCard
                            width="100%"
                            id="Offense Information Time Between Offense and Sentence Date"
                            title="Offense Information  >  Time Between Offense and Sentence Date"
                            graph={
                                <SingleBarGraph
                                    data={props.data.timeBetweenOffenseSentence ? props.data.timeBetweenOffenseSentence : []}
                                    keys={tdcjdata.timeBetweenOffenseSentence}
                                    yaxis="Number of Inmates"
                                    xaxis="Length of Time (years)"
                                    type="cc"
                                />
                            }
                        table={<TDCJTable data={props.data.timeBetweenOffenseSentence} keys={tdcjdata.timeBetweenOffenseSentence} single={true} id="Offense Information - Time Between Offense and Sentence Date" />}
                        definitions={[["", "This chart shows the length of time between the offense and sentence dates in years."]]}
                            addToReports={props.addToReportPage}
                        />

                        <SingleCard
                            width="100%"
                        id="Offense Information Offenses By Chapter Over Time"
                        title="Offense Information  >  Offenses By Chapter Over Time"
                        type="Grantee Data"
                            graph={
                                <StackedBarGraph
                                    data={props.data.offenseByChapterOverTime}
                                    keys={tdcjdata.offenseByChapterOverTime}
                                    xaxis="Year"
                                    yaxis="Number of Inmates"
                                    trendLine={true}
                                    trendLineLegend="Total Inmates"
                                />
                            }
                        table={<TDCJTable data={props.data.offenseByChapterOverTime} keys={tdcjdata.offenseByChapterOverTime} id="Offense Information - Offenses By Chapter Over Time" />}
                        definitions={[["","This chart shows the offenses by chapter over time. Only the location filter applies to this chart; the offenses filter is not applicable."]]}
                            addToReports={props.addToReportPage}
                        />

                    <SingleCard
                        width="100%"
                        id="Inmate Demographics Age"
                        title="Inmate Demographics  >  Age"
                        graph={
                            <SingleBarGraph
                                data={props.data.inmatesAge ? props.data.inmatesAge : tdcjmockdata.inmatesAge}
                                keys={tdcjdata.inmatesAge}
                                xaxis="Age"
                                yaxis="Number of Individuals"
                                type="cc"
                            />
                        }
                        table={<TDCJTable data={props.data.inmatesAge} keys={tdcjdata.inmatesAge} id="Inmate Demographics - Age" single={true} />}
                        definitions={[["", "This chart shows the age of the inmates at the time of the offense."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        width="100%"
                        id="Inmate Demographics Race/Ethnicity"
                        title="Inmate Demographics  >  Race/Ethnicity"
                        graph={
                            <SingleBarGraph
                                data={props.data.inmatesRace ? props.data.inmatesRace : tdcjmockdata.inmatesRace}
                                keys={tdcjdata.inmatesRace}
                                xaxis="Race/Ethnicity"
                                yaxis="Number of Individuals"
                                type="cc"
                            />
                        }
                        table={<TDCJTable data={props.data.inmatesRace} keys={tdcjdata.inmatesRace} id="Inmate Demographics - Race/Ethnicity" single={true} />}
                        definitions={[["", "This chart shows the race/ethnicity of the inmates."]]}
                        addToReports={props.addToReportPage}
                    />

                    <SingleCard
                        width="100%"
                        id="Inmate Demographics Gender"
                        title="Inmate Demographics  >  Gender"
                        graph={
                            <SingleBarGraph
                                data={props.data.inmatesGender ? props.data.inmatesGender : tdcjmockdata.inmatesGender}
                                keys={tdcjdata.inmatesGender}
                                yaxis="Number of Individuals"
                                xaxis="Gender"
                                type="cc"
                            />
                        }
                        table={<TDCJTable data={props.data.inmatesGender} keys={tdcjdata.inmatesGender} single={true} id="Inmate Demographics - Gender" />}
                        definitions={[["", "This chart shows the gender of the inmates. The only options in the data source are male and female."]]}
                        addToReports={props.addToReportPage}
                    />

                </div>
            </div>
        );
    }

    const renderSingleChart = () => {
        const charts = renderAllCharts();
        const singleCards = charts.props.children.props.children;

        const index = singleCards.findIndex((card) => card.props.id === props.chartId);
        if (index <= -1) {
            return <Fragment></Fragment>;
        }

        const elementToRender = singleCards[index];
        return <SingleCard
            {...elementToRender.props}
            title={props.chartTitle || props.title}
            description={props.chartInsight || ""}
            shouldUseNewStyles={true}
        />;
    }

    return (
        props.chartId
            ? renderSingleChart()
            : renderAllCharts()
    );
}
