/* eslint-disable array-callback-return */
import { countyData } from "../../../Data/countyJson";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { regionData } from "../../../Data/regionJsonV2";

export function ServicesLayer(map, location, data, legend, report) {
  var locationSelection;
    for (let i = 0; i < location.length; i++){
        if (location[i].name === 'Location ' && location[i].filter === 'Services' && location[i].stateLocation === 'TX'){
            locationSelection = location[i].value[0];
        }
    }

    if (locationSelection === "State"){
        map.on("load", function () {
            if (data.orgList){
              data.orgList.map((item) => {
                  if (item.org_type !== "Juvenile Justice") {
                    // if (item.org_type === "Bringing Justice" && legend.includes("Bringing Justice")) {
                    //   var grantee;
                    //   if (item.grantee_type){
                    //     grantee = `<p>Grantee: ${item.grantee_type}</p>`
                    //   } else {
                    //     grantee = ``
                    //   }
                    //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    //     `<p>${item.organization}</p>
                    //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                    //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>
                    //      ${grantee}`
                    //     );
                    //     return new mapboxgl.Marker({ color: "#F28E2B", scale: report ? 0.2 : 0.5 })
                    //     .setLngLat([item.long, item.lat])
                    //     .setPopup(popup)
                    //     .addTo(map);
                    // } 
                    if (item.org_type === "CSEY Advocacy" && legend.includes("CSEY Advocacy")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                        return new mapboxgl.Marker({ color: "#4E79A7", scale:  report ? 0.2 : 0.5 })
                        .setLngLat([item.long, item.lat])
                        .setPopup(popup)
                        .addTo(map);
                    } else if (item.org_type === "Drop-In Center" && legend.includes("Drop-In Center")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                        return new mapboxgl.Marker({ color: "#E15759", scale:  report ? 0.2 : 0.5 })
                        .setLngLat([item.long, item.lat])
                        .setPopup(popup)
                        .addTo(map);
                    } else if (item.org_type === "Emergency Shelter" && legend.includes("Emergency Shelter")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#76B7B2", scale:  report ? 0.2 : 0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } 
                    // else if (item.org_type === "Innovative Services" && legend.includes("Innovative Services")) {
                    //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    //     `<p>${item.organization}</p>
                    //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                    //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    //     );
                    //   return new mapboxgl.Marker({ color: "#59A14E", scale:  report ? 0.2 : 0.5 })
                    //   .setLngLat([item.long, item.lat])
                    //   .setPopup(popup)
                    //   .addTo(map);
                    // } 
                    else if (item.org_type === "Long Term Residential" && legend.includes("Long Term Residential")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#EDC949", scale: report ? 0.2 :  0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } 
                    // else if (item.org_type === "Prevention" && legend.includes("Prevention")) {
                    //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    //     `<p>${item.organization}</p>
                    //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                    //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    //     );
                    //   return new mapboxgl.Marker({ color: "#B07AA2", scale: report ? 0.2 :  0.5 })
                    //   .setLngLat([item.long, item.lat])
                    //   .setPopup(popup)
                    //   .addTo(map);
                    // } 
                    // else if (item.org_type === "Therapy/Counseling" && legend.includes("Therapy/Counseling")) {
                    //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    //     `<p>${item.organization}</p>
                    //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                    //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    //     );
                    //   return new mapboxgl.Marker({ color: "#FF9DA7", scale: report ? 0.2 :  0.5 })
                    //   .setLngLat([item.long, item.lat])
                    //   .setPopup(popup)
                    //   .addTo(map);
                    // } 
                    // else if (item.org_type === "Other" && legend.includes("Other")) {
                    //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    //     `<p>${item.organization}</p>
                    //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                    //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    //     );
                    //   return new mapboxgl.Marker({ color: "#9C755F", scale: report ? 0.2 :  0.5 })
                    //   .setLngLat([item.long, item.lat])
                    //   .setPopup(popup)
                    //   .addTo(map);
                    // } 
                    else if (item.org_type === "National Referral Directory" && legend.includes("National Referral Directory")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#BAB0AC", scale: report ? 0.2 :  0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } 
                    // else if (item.org_type === "OVC/OTIP Grantees" && legend.includes("OVC/OTIP Grantees")) {
                    //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    //     `<p>${item.organization}</p>
                    //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                    //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    //     );
                    //   return new mapboxgl.Marker({ color: "#BAB0AC", scale: report ? 0.2 :  0.5 })
                    //   .setLngLat([item.long, item.lat])
                    //   .setPopup(popup)
                    //   .addTo(map);
                    // }
                  }
                });
            }
          });
    } else if (locationSelection === "Region") {
        map.on("load", function () {
            map.addSource("region", {
              type: "geojson",
              data: regionData,
            });
            map.addLayer({
              id: "region",
              type: "line",
              source: "region",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#28ADE3",
                "line-width": 1,
              },
            });
    
            map.addLayer({
              id: "region-fills",
              type: "fill",
              source: "region",
              layout: {},
              paint: {
                "fill-color": "#28ADE3",
                "fill-opacity": [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  0.5,
                  0,
                ],
              },
            });

            if (data.orgList){
              data.orgList.map((item) => {
                  if (item.org_type !== "Juvenile Justice") {
                    if (item.org_type === "Bringing Justice" && legend.includes("Bringing Justice")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                        return new mapboxgl.Marker({ color: "#F28E2B", scale: report ? 0.2 :  0.5 })
                        .setLngLat([item.long, item.lat])
                        .setPopup(popup)
                        .addTo(map);
                    } else if (item.org_type === "CSEY Advocacy" && legend.includes("CSEY Advocacy")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                        return new mapboxgl.Marker({ color: "#4E79A7", scale: report ? 0.2 :  0.5 })
                        .setLngLat([item.long, item.lat])
                        .setPopup(popup)
                        .addTo(map);
                    } else if (item.org_type === "Drop-In Center" && legend.includes("Drop-In Center")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                        return new mapboxgl.Marker({ color: "#E15759", scale: report ? 0.2 :  0.5 })
                        .setLngLat([item.long, item.lat])
                        .setPopup(popup)
                        .addTo(map);
                    } else if (item.org_type === "Emergency Shelter" && legend.includes("Emergency Shelter")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#76B7B2", scale: report ? 0.2 :  0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } else if (item.org_type === "Innovative Services" && legend.includes("Innovative Services")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#59A14E", scale: report ? 0.2 :  0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } else if (item.org_type === "Long Term Residential" && legend.includes("Long Term Residential")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#EDC949", scale: report ? 0.2 :  0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } else if (item.org_type === "Prevention" && legend.includes("Prevention")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#B07AA2", scale: report ? 0.2 :  0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } else if (item.org_type === "Therapy/Counseling" && legend.includes("Therapy/Counseling")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#FF9DA7", scale:  report ? 0.2 : 0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } else if (item.org_type === "Other" && legend.includes("Other")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#9C755F", scale:  report ? 0.2 : 0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } else if (item.org_type === "National Referral Directory" && legend.includes("National Referral Directory")) {
                      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                        `<p>${item.organization}</p>
                         <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                         <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                        );
                      return new mapboxgl.Marker({ color: "#BAB0AC", scale:  report ? 0.2 : 0.5 })
                      .setLngLat([item.long, item.lat])
                      .setPopup(popup)
                      .addTo(map);
                    } 
                    // else if (item.org_type === "OVC/OTIP Grantees" && legend.includes("OVC/OTIP Grantees")) {
                    //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    //     `<p>${item.organization}</p>
                    //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                    //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    //     );
                    //   return new mapboxgl.Marker({ color: "#BAB0AC", scale: report ? 0.2 :  0.5 })
                    //   .setLngLat([item.long, item.lat])
                    //   .setPopup(popup)
                    //   .addTo(map);
                    // }
                  }
                });
            }
            var popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
              });
        
              map.on("mousemove", "region-fills", function (e) {
                if (hoveredStateId !== e.features[0].id) {
                  if (e.features.length > 0) {
                    if (hoveredStateId) {
                      map.setFeatureState(
                        { source: "region", id: hoveredStateId },
                        { hover: false }
                      );
                    }
                    hoveredStateId = e.features[0].id;
                    map.setFeatureState(
                      { source: "region", id: hoveredStateId },
                      { hover: true }
                    );
                  }
        
                  // var coordinates = e.features[0].geometry.coordinates[0][0];
                  // if (coordinates.length > 2) {
                  //   for (var i = 2; i < coordinates.length; i--) {
                  //     coordinates.pop();
                  //   }
                  // }
                  var description = e.features[0].properties.region;
                  popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
                }
              });
        
              map.on("mouseleave", "region-fills", function () {
                if (hoveredStateId) {
                  map.setFeatureState(
                    { source: "region", id: hoveredStateId },
                    { hover: false }
                  );
                }
                hoveredStateId = null;
                popup.remove();
              });
            });

    } else {
        var hoveredStateId = null;

        map.on("load", function () {
        map.addSource("county", {
            type: "geojson",
            data: countyData,
        });
        map.addLayer({
            id: "county",
            type: "line",
            source: "county",
            layout: {
            "line-join": "round",
            "line-cap": "round",
            },
            paint: {
            "line-color": "#28ADE3",
            "line-width": 1,
            },
        });

        map.addLayer({
            id: "county-fills",
            type: "fill",
            source: "county",
            layout: {},
            paint: {
            "fill-color": "#28ADE3",
            "fill-opacity": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                0.5,
                0,
            ],
            },
        });

        if (data.orgList){
          data.orgList.map((item) => {
              if (item.org_type !== "Juvenile Justice") {
                if (item.org_type === "Bringing Justice" && legend.includes("Bringing Justice")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                    return new mapboxgl.Marker({ color: "#F28E2B", scale:  report ? 0.2 : 0.5 })
                    .setLngLat([item.long, item.lat])
                    .setPopup(popup)
                    .addTo(map);
                } else if (item.org_type === "CSEY Advocacy" && legend.includes("CSEY Advocacy")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                    return new mapboxgl.Marker({ color: "#4E79A7", scale:  report ? 0.2 : 0.5 })
                    .setLngLat([item.long, item.lat])
                    .setPopup(popup)
                    .addTo(map);
                } else if (item.org_type === "Drop-In Center" && legend.includes("Drop-In Center")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                    return new mapboxgl.Marker({ color: "#E15759", scale:  report ? 0.2 : 0.5 })
                    .setLngLat([item.long, item.lat])
                    .setPopup(popup)
                    .addTo(map);
                } else if (item.org_type === "Emergency Shelter" && legend.includes("Emergency Shelter")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                  return new mapboxgl.Marker({ color: "#76B7B2", scale:  report ? 0.2 : 0.5 })
                  .setLngLat([item.long, item.lat])
                  .setPopup(popup)
                  .addTo(map);
                } else if (item.org_type === "Innovative Services" && legend.includes("Innovative Services")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                  return new mapboxgl.Marker({ color: "#59A14E", scale:  report ? 0.2 : 0.5 })
                  .setLngLat([item.long, item.lat])
                  .setPopup(popup)
                  .addTo(map);
                } else if (item.org_type === "Long Term Residential" && legend.includes("Long Term Residential")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                  return new mapboxgl.Marker({ color: "#EDC949", scale:  report ? 0.2 : 0.5 })
                  .setLngLat([item.long, item.lat])
                  .setPopup(popup)
                  .addTo(map);
                } else if (item.org_type === "Prevention" && legend.includes("Prevention")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                  return new mapboxgl.Marker({ color: "#B07AA2", scale:  report ? 0.2 : 0.5 })
                  .setLngLat([item.long, item.lat])
                  .setPopup(popup)
                  .addTo(map);
                } else if (item.org_type === "Therapy/Counseling" && legend.includes("Therapy/Counseling")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                  return new mapboxgl.Marker({ color: "#FF9DA7", scale:  report ? 0.2 : 0.5 })
                  .setLngLat([item.long, item.lat])
                  .setPopup(popup)
                  .addTo(map);
                } else if (item.org_type === "Other" && legend.includes("Other")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                  return new mapboxgl.Marker({ color: "#9C755F", scale:  report ? 0.2 : 0.5 })
                  .setLngLat([item.long, item.lat])
                  .setPopup(popup)
                  .addTo(map);
                } else if (item.org_type === "National Referral Directory" && legend.includes("National Referral Directory")) {
                  const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                    `<p>${item.organization}</p>
                     <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                     <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                    );
                  return new mapboxgl.Marker({ color: "#BAB0AC", scale:  report ? 0.2 : 0.5 })
                  .setLngLat([item.long, item.lat])
                  .setPopup(popup)
                  .addTo(map);
                } 
                // else if (item.org_type === "OVC/OTIP Grantees" && legend.includes("OVC/OTIP Grantees")) {
                //   const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
                //     `<p>${item.organization}</p>
                //      <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
                //      <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
                //     );
                //   return new mapboxgl.Marker({ color: "#BAB0AC", scale: report ? 0.2 :  0.5 })
                //   .setLngLat([item.long, item.lat])
                //   .setPopup(popup)
                //   .addTo(map);
                // }
              }
            });
        }
        
        });

        var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        });

        map.on("mousemove", "county-fills", function (e) {
        if (hoveredStateId !== e.features[0].id) {
            if (e.features.length > 0) {
            if (hoveredStateId) {
                map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: false }
                );
            }
            hoveredStateId = e.features[0].id;
            map.setFeatureState(
                { source: "county", id: hoveredStateId },
                { hover: true }
            );
            }

            // var coordinates;
            // if (e.features[0].geometry.coordinates.length > 1) {
            // coordinates = e.features[0].geometry.coordinates[0][0][0];
            // } else {
            // coordinates = e.features[0].geometry.coordinates[0][0];
            // }

            // if (coordinates.length > 2) {
            // for (var i = 2; i < coordinates.length; i--) {
            //     coordinates.pop();
            // }
            // }
            var description = e.features[0].properties.COUNTY;
            popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
        }
        });

        map.on("mouseleave", "county-fills", function () {
        if (hoveredStateId) {
            map.setFeatureState(
            { source: "county", id: hoveredStateId },
            { hover: false }
            );
        }
        hoveredStateId = null;
        popup.remove();
        });
    }

}